<template>
  <div
    v-if="scoreReportLoading"
  >
    <v-skeleton-loader
      type="table-thead, image, list-item-avatar@3"
    />
  </div>

  <div
    v-else
  >
    <v-container
      v-if="scoreReport != null"
      class="container-reports"
      :class="fromComposite ? 'pa-0' : null"
    >
      <div
        v-if="showHeader"
      >
        <app-header
          :title="`${assessment.set} - ${assessment.section}`"
        />
      </div>

      <div v-if="!showSummaryInRows" 
        class="d-flex subsection-details-wrapper">
        <div class="donut-chart-wrapper">
          <div class="donut-chart">
            <donut-chart
              :chart-id="getChartId(scoreReport.summary.category)"
              :chart-title="'Correct'"
              chart-title-font-size="28px"
              :chart-range="false"
              :display-percentage="true"
              :average-score="averageScore(scoreReport.summary)"
              :min-score="1"
              :max-score="100"
              :color="'#f4701f'"
            />
          </div>
          <div class="donut-chart-content">
            <div
              class="donut-detail"
            >
              <h1>{{ scoreReport.summary.questionsTotal }}</h1>
              <span>Total Questions</span>
            </div>
            <div
              class="donut-detail"
            >
              <h1>{{ scoreReport.summary.correctTotal }}</h1>
              <span>Correct Answers</span>
            </div>
            <div
              class="donut-detail"
            >
              <h1>{{ scoreReport.summary.incorrectTotal }}</h1>
              <span>Incorrect Answers</span>
            </div>
            <div
              class="donut-detail"
            >
              <h1>{{ scoreReport.summary.blankTotal }}</h1>
              <span>Blank Answers</span>
            </div>
          </div>
        </div>

        <category-bars-percentage
          :category-list-prop="scoreReport.subsectionDetails"
        />
      </div>

      <div v-else
       class="d-flex flex-column subsection-details-wrapper-comparison"
      >
        <div class="donut-chart-wrapper">
          <div class="donut-chart">
            <donut-chart
              :chart-id="getChartId(scoreReport.summary.category)"
              :chart-title="'Correct'"
              chart-title-font-size="28px"
              :chart-range="false"
              :display-percentage="true"
              :average-score="averageScore(scoreReport.summary)"
              :min-score="1"
              :max-score="100"
              :color="'#f4701f'"
            />
          </div>
          <div class="donut-chart-content">
            <div
              class="donut-detail"
            >
              <h1>{{ scoreReport.summary.questionsTotal }}</h1>
              <span>Total Questions</span>
            </div>
            <div
              class="donut-detail"
            >
              <h1>{{ scoreReport.summary.correctTotal }}</h1>
              <span>Correct Answers</span>
            </div>
            <div
              class="donut-detail"
            >
              <h1>{{ scoreReport.summary.incorrectTotal }}</h1>
              <span>Incorrect Answers</span>
            </div>
            <div
              class="donut-detail"
            >
              <h1>{{ scoreReport.summary.blankTotal }}</h1>
              <span>Blank Answers</span>
            </div>
          </div>
        </div>

        <category-bars-percentage
          :category-list-prop="scoreReport.subsectionDetails"
        />
      </div>

      <horizon-multilevel-table
        :columns="subsectionColumns"
        :expanded-columns="subsectionExpandedDetailsColumns"
        :data="scoreReport.subsectionDetails"
        :sub-table-key="'topicAreaDetails'"
        :small="true"
        :table-setup="subsectionTableSetup"
        :clickable="true"
        :row-expand-data="subsectionDetails"
        :loading-sublevel="loadingSublevel"
        class="web-only"
        @loadDetails="loadSubsectionQuestions"
        @rowClicked="openLearnosityPreview"
      />
      <div
        v-if="questionsAndAnswersVisible && showQuestionsList"
      >
        <div class="table-header">
          <div class="table-title">
            Question & Answer List
          </div>
          <v-btn
            class="btn-default btn-default__secondary btn-default__white"
            @click="loadLearnosityReport()"
          >
            View Assessment Questions & Correct Answers
            <img
              alt="right-arrow"
              class="ml-2"
              height="13"
              width="13"
              :src="require(`@/assets/icons/right-arrow.png`)"
            >
          </v-btn>
        </div>
        <horizon-table
          :columns="standardItemAnalysisColumns"
          :data="questionsAndAnswers"
          :small="true"
          :clickable="true"
          class="web-only"
          @row-clicked="openLearnosityPreview"
        />
      </div>

      <dialog-learnosity-preview
        :active="previewDialog"
        :title="previewTitle"
        :preview-id="previewId"
        @closeDialog="closeLearnosityPreview"
      />
    </v-container>

    <div v-else>
      The report could not be loaded.
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
import AppHeader from "@/components/shared/AppHeader.vue";
import DonutChart from '@/components/shared/charts/DonutChart'
import HorizonMultilevelTable from '@/components/shared/HorizonMultilevelTable'
import HorizonTable from '@/components/shared/HorizonTable'
import CategoryBarsPercentage from '@/components/shared/charts/CategoryBarsPercentage'
import DialogLearnosityPreview from '@/components/shared/DialogLearnosityPreview'

import {StudentScoreReportApi, StaffStudentAssessmentApi, StaffStudentResultsApi, ParentStudentsApi} from '@/api'

export default {

  components: {
    AppHeader,
    CategoryBarsPercentage,
    DonutChart,
    HorizonTable,
    HorizonMultilevelTable,
    DialogLearnosityPreview
  },

  props: {
    studentAssessmentId: {type: [Number, String], default: null, required: false},
    scoreReportProp: {type: Object, default: null, required: false},
    multipart: {type: Boolean, default: false, required: false},
    showHeader: {type: Boolean, default: true, required: false},
    showQuestionsList: {type: Boolean, default: true, required: false},
    showSummaryInRows: {type: Boolean, default: false, required: false},
    fromComposite: Boolean,
    studentId: {type: [Number, String], default: null, required: false},
    assessmentType: {type: String, default: undefined, required: false},
  },

  data() {
    return {
      scoreReport: null,
      assessment: {},
      scoreReportLoading: true,
      questionsAndAnswers: [],
      questionsAndAnswersVisible: false,
      subsectionColumns: [
        {column: 'subsectionArea', display: 'Subsections', width: 40, title: true, secondaryDisplay: 'topicArea'},
        {column: 'percent', display: 'Correct (%)', width: 15, formatter: 'decimalToPercent'},
        {column: 'correctTotal', display: 'Correct (#)', width: 15, formatter: 'intToDecimal'},
        {column: 'incorrectTotal', display: 'Incorrect (#)', width: 15, formatter: 'intToDecimal'},
        {column: 'blankTotal', display: 'Blank (#)', width: 15, formatter: 'intToDecimal'}
      ],
      subsectionExpandedDetailsColumns: [
        {column: 'subsectionArea', display: 'Subsections', width: 40, title: true, secondaryDisplay: 'question'},
        {column: '', display: 'Correct (100%)', width: 15, formatter: 'decimalToPercent'},
        {column: 'correctPercent', display: 'Correct (#)', width: 15, formatter: 'intToDecimal'},
        {column: 'incorrectPercent', display: 'Incorrect (#)', width: 15, formatter: 'intToDecimal'},
        {column: 'blankPercent', display: 'Blank (#)', width: 15, formatter: 'intToDecimal'},
      ],
      subsectionTableSetup: {
        headerColumns: true,
        header: true
      },
      standardItemAnalysisColumns: [
        {column: 'question', display: 'Question #'},
        {column: 'studentAnswer', display: 'Your Answer'},
        {column: 'correctAnswer', display: 'Correct Answer'},
        {column: 'timing', display: 'Time Spent'},
        {column: 'subsectionArea', display: 'Subsection'},
        {column: 'topicArea', display: 'Area'}
      ],
      previewTitle: '',
      previewDialog: false,
      previewId: -1,
      subsectionDetails: [],
      loadingSublevel: false,
    }
  },

  created() {
    this.initAnsweredListColumns()
    if (this.studentAssessmentId != null && this.scoreReportProp == null) {
      this.loadScoreReport(this.studentAssessmentId)
    } else {
      this.scoreReport = this.scoreReportProp
      this.loadQuestionAndAnswers(this.studentAssessmentId)
      this.scoreReportLoading = false
    }
  },

  methods: {
    ...mapGetters(["userRole", 'userSchool']),

    averageScore(sectionDetails) {
      if (sectionDetails == null) {
        return ""
      } else {
        return (((sectionDetails.correctTotal / sectionDetails.questionsTotal) * 100).toFixed(1))
      }
    },

    initAnsweredListColumns() {
      this.standardItemAnalysisColumns = [
        {column: 'question', display: 'Question #'},
        {column: 'studentAnswer', display: 'Your Answer', columnFn: this.displayAnswerState},
        {column: 'correctAnswer', display: 'Correct Answer'},
        {column: 'timing', display: 'Time Spent', columnFn: this.displayTiming},
        {column: 'subsectionArea', display: 'Subsection'},
        {column: 'topicArea', display: 'Area'}
      ]

    },

    displayAnswerState(answer) {
      if (answer.studentAnswerCorrect === null) {
        return ''
      }
      return answer?.studentAnswerCorrect ? `<span class="text-success font-weight-700">${answer.studentAnswer}</b></span>` : `<span class="text-danger font-weight-700">${answer.studentAnswer}</span>`
    },

    displayTiming(time) {
      return `${time.timing} Seconds`
    },

    async loadScoreReport(id) {
      try {
        const response = await StudentScoreReportApi.get(id)
        this.assessment = response.studentAssessment.assessment
        this.scoreReport = response.studentAssessment.report
        this.loadQuestionAndAnswers(id)
      } catch (e) {
        this.addNotification('error', 'Could not load the report.')
      }
      this.scoreReportLoading = false
    },

    async loadQuestionAndAnswers(id) {
      try {
        const userRole = this.userRole()
        if (userRole === "Student") {
          this.questionsAndAnswers = await StudentScoreReportApi.questions_and_answers({
            assessmentId: id,
            multipart: this.multipart
          })
        } else if(userRole === 'Parent') {
          this.questionsAndAnswers = await ParentStudentsApi.questionsAndAnswers({
            studentId: this.studentId,
            assessmentId: this.studentAssessmentId,
            multipart: this.multipart
          })
        } else {
          this.questionsAndAnswers = await StaffStudentAssessmentApi.questions_and_answers({
            sectionId: this.$route.params.sectionId,
            assessmentId: this.studentAssessmentId,
            multipart: this.multipart
          })
        }
        this.questionsAndAnswersVisible = true
      } catch (e) {
        this.questionsAndAnswersVisible = false
      }
    },

    getChartId(name) {
      let r = Math.floor(Math.random() * 100)
      return `chart-${name.replace('(', '').replace(')', '').replaceAll(' ', '-')}-${r}`
    },

    loadLearnosityReport() {
      if (this.userRole() === "Student") {
        const routeData = this.$router.resolve({
          name: 'SessionDetailByItemReport',
          params: {assessmentId: this.studentAssessmentId}
        });
        window.open(routeData.href, '_blank');
      } else {
        let pathName = "StaffSessionDetailByItemReport"
        if (this.$route.params.sectionId != null) {
          pathName = "StaffSessionDetailByItemReportWithSection"
        }
        const routeData = this.$router.resolve({
          name: pathName,
          params: {assessmentId: this.studentAssessmentId, sectionId: this.$route.params.sectionId}
        });
        window.open(routeData.href, '_blank');
      }
    }, 

    openLearnosityPreview(item) {
      this.previewId = item.questionId
      if (isNaN(Number(item.question))) {
        this.previewTitle = item.question
      }
      else {
        this.previewTitle = `${this.scoreReport.summary.category} #${item.question}`
      }
      this.previewDialog = false
      this.$nextTick(() => {
        this.previewDialog = true
      })
    },

    closeLearnosityPreview() {
      this.previewDialog = false
    },

    async loadSubsectionQuestions(item) {
      try {
        this.loadingSublevel = true
        let userRole = this.userRole()
        if (userRole == "Student") {
          let response = await StudentScoreReportApi.getTopicAreaBreakdown({
            studentAssessmentId: this.studentAssessmentId,
            topicAreaId: item.topicAreaId,
            multipart: this.multipart
          })
          this.subsectionDetails = response?.topicAreaQuestions
        } else {
          let response = await StaffStudentResultsApi.getTopicAreaBreakdownForStudent({
            topicAreaId: item.topicAreaId,
            sectionId: this.$route.params.sectionId,
            studentAssessmentId: this.studentAssessmentId,
            multipart: this.multipart
          })
          this.subsectionDetails = response?.topicAreaQuestions
        }
      } catch {
        this.addNotification('error', 'There was a problem loading questions')
      } finally {
        this.loadingSublevel = false
      }
    }


  },
}
</script>

<style scoped lang="scss">
// @import '~@styles/variables';

.container-reports {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  color: $primaryColor;
  margin-bottom: 0;
}

h2, .h2 {
  font-size: 18px;
  font-weight: 700;
  color: $primaryColor;
  margin-bottom: 0;
}

h3, .h3 {
  font-size: 12px;
  margin-bottom: 0;
}

h4 {
  font-size: 10px;
  font-weight: 600;
  color: $subHeadingColor;
  margin-bottom: 0;
}

h5 {
  font-size: 10px;
  font-weight: 400;
  color: $subHeadingColor;
  margin-bottom: 0;
}

.container-reports {
  max-width: 1110px;
  padding: 20px 30px 30px;
}


.btn-report {
  background-color: white;
  color: #345165;
  font-size: 12px;

  &:hover {
    background-color: #345165;
    color: white;
  }
}

.instructions {
  font-size: 16px;
  color: #6b6b6b;
  padding-bottom: 14px;
}

.tab {
  font-size: 18px;

  &.disabled {
    cursor: not-allowed;
  }
}

.subsection-details-wrapper {
  padding: 20px;
}

.bar-chart-wrapper {
  padding-left: 15px;
  border-left: 1px solid #e8e8e8;

  .bar-chart-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-self: center;

    .bar-row {
      display: flex;
      align-items: center;
      padding: 6px 0;

      > div {
        display: flex;
        justify-content: space-between;
      }

      :first-child {
        padding-right: 8px;
      }
    }

    .bar {
      height: 20px;
      background-color: $themeLtGray;
      width: 100%;
      position: relative;
      border-radius: 5px;

      &.completed {
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    span {
      font-size: 12px;
    }

    .bar-specs {
      display: flex;
      justify-content: space-between;
      width: 30%;

      span:last-of-type {
        text-align: right;
        font-weight: 600;
      }
    }
  }
}

.donut-chart-wrapper {
  padding: 10px 0;
  display: flex;
  align-items: center;

  .donut-chart-content {
    padding-left: 20px;
  }

  .donut-detail {
    display: flex;
    align-items: center;
    padding: 5px;

    h1 {
      margin-bottom: 3px;
      line-height: 32px;
      width: 30%;
      text-align: right;
      padding-right: 8px;
    }

    span {
      margin-left: 5px;
    }
  }
}

.table-header {
  background-color: #F3F3F3;
  min-height: 70px;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .table-title {
    font-weight: 900;
    color: #f4701f;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
  }

  .table-instruction {
    margin-left: 20px;
    color: #6b6b6b;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}

.web-only {
  display: block;
}

.w-tab-menu {
  display: none;
}

.subsection-details-wrapper {
  .donut-chart-wrapper {
    width: 45%;

    .donut-chart {
      width: 50%;
    }

    .donut-chart-content {
      width: 50%;
      padding-left: 0px;
    }
  }

  .bar-chart-wrapper {
    width: 55%;
  }
}

.subsection-details-wrapper-comparison {
  margin: 10px 0px;

  .donut-chart-wrapper {
    width: 90%;
    margin: 0 auto;

    .donut-chart {
      width: 50%;
    }

    .donut-chart-content {
      width: 50%;
      padding-left: 0px;
    }
  }

  .bar-chart-wrapper {
    width: 90%;
    margin: 0 auto;
    border-left: 0;
  }
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .subsection-details-wrapper {
    .donut-chart-wrapper {
      width: 50%;
    }

    .bar-chart-wrapper {
      width: 50%;
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .subsection-details-wrapper {
    display: block !important;

    .donut-chart-wrapper {
      width: 100%;
    }

    .bar-chart-wrapper {
      width: 100%;
      padding-left: 0;
      border-left: 0;
      padding-top: 15px;
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .web-only {
    display: none;
  }

  .report-title {
    h1 {
      font-size: 18px;
    }
  }

  .subsection-details-wrapper {
    padding-left: 8px;
    padding-right: 8px;

    .tabs-row {
      .tab {
        padding: 10px 13px;
        font-size: 14px;
      }
    }

    .donut-chart-wrapper {
      .donut-chart {
        width: 40%;
      }

      .donut-chart-content {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .tab {
    display: none;
  }

  .w-tab-menu {
    display: block;
    width: 100%;

    .w-tab-menu-item {
      width: 100%;
      transition: .2s;
      cursor: pointer;
      color: $mutedText;
      padding: 13px 25px;
      text-align: center;
      border-radius: 7px;

      &.selected {
        background-color: $primaryColor;
        color: #fff;
      }

      &:not(.selected):hover {
        color: $themeGray;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .subsection-details-wrapper {
    .donut-chart-wrapper {
      display: block;

      .donut-chart {
        width: 100%;
      }

      .donut-chart-content {
        padding-top: 15px;
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
// @import '~@styles/variables';

.subsection-loader-container {
  height: 60px;
  background: #F3F3F3;
  border-top: 1px solid #DEE2E6;
  border-bottom: 1px solid #DEE2E6;

  & > p {
    font-weight: 900;
    color: #f4701f;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
    padding-left: 20px;
    margin: 0;
    line-height: 60px;
  }
}

.subsection-details-wrapper {
  .donut {
    .inner-donut-text {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 56px;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 38px;
      }
    }
  }
}

@media screen and (max-width: 549px) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 32px;
      }
    }
  }
}

@media screen and (max-width: 429px) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 28px;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 56px;
      }
    }
  }
}

@media screen and (max-width: 329px) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 42px;
      }
    }
  }
}
</style>
